body > #root {
  margin-top: 65px;
  height: 100vh;
}

input:focus,
textarea {
  outline: none !important;
}

.grecaptcha-badge {
  visibility: hidden;
}
